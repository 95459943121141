import {useUserStore} from '@/stores/user';
import {useSettingStore} from '@/stores/setting';

export default defineNuxtRouteMiddleware((to, from) => {
    if (process.server) {
        return;
    }
    if (process.client) {
        const userStore = useUserStore();
        const token = userStore.getToken;
        if (!token) {

            const settingStore = useSettingStore();
            settingStore.updateLoginDialogVisible(true);
            return abortNavigation()
        }
    }
});
